<template>
  <div class="mt-5 md:mt-8">
    <ListPapersCompact
      v-if="isSite('altinget_no')"
      class="no-print hidden lg:block"
    />
    <div class="container no-print">
      <LayoutColumns>
        <template #left>
          <ListNames
            class="order-3 lg:order-none"
            :count="5"
            placement="side"
          />
          <ListJobs
            v-if="isSite('altinget_se')"
            class="order-5 lg:order-none"
            :count="4"
            :random="true"
          />
          <AdsConceptAd class="hidden lg:block" id="altinget-rec1" />
          <AdsConceptAd
            v-if="isSite('altinget_se', 'altinget_no')"
            class="lg:hidden order-2"
            id="altinget-mob2"
          />
          <ListQA
            v-if="isSite('altinget_no')"
            class="order-last lg:order-none"
            :count="5"
            placement="side"
          />
          <ListArticles
            v-if="isSite('altinget_dk')"
            class="order-8 lg:order-none"
            :count="3"
            :offset="0"
            :article-type="115"
            type="articletype"
            label="Podcast"
            :same-size="true"
            link="/podcast"
            link-label="ShowAll"
            placement="side"
          />
          <CommonUserWidget class="order-9 lg:order-none" placement="side" />
        </template>
        <template #main>
          <ListArticles
            class="order-2 lg:order-none"
            :frontend="true"
            :count="4"
            :offset="0"
            :paper="1"
            :lazy="false"
            :preload-first-image="true"
            placement="main"
          />
          <ArticleSponsoredInlineTeaser
            v-if="isSite('altinget_no')"
            class="order-3 lg:order-none"
          />
          <ArticleSponsoredTeaser v-else class="order-3 lg:order-none" />
          <ListBubbles
            :class="[
              isSite('altinget_dk') ? 'order-2' : 'order-1',
              'lg:order-none',
            ]"
          />
          <ListArticles
            class="order-4 lg:order-none"
            :offset="4"
            :count="0"
            :frontend="true"
            :paper="1"
            placement="main"
          />
          <NewsletterSignup class="hidden lg:block" />
        </template>
        <template #right>
          <ListJobs
            v-if="isSite('altinget_dk', 'altinget_no')"
            class="order-5 lg:order-none"
            :count="5"
            :random="true"
          />
          <AdsConceptAd
            v-if="isSite('altinget_dk')"
            class="lg:hidden order-3"
            id="altinget-mob2"
          />
          <template v-if="isSite('altinget_no')">
            <AdsConceptAd class="hidden lg:block" id="altinget-rec2" />
            <AdsConceptAd class="lg:hidden order-6" id="altinget-mob3" />
          </template>
          <ListArticles
            v-else-if="isSite('altinget_se')"
            class="order-5 lg:order-none"
            :count="5"
            :offset="0"
            :article-type="90"
            type="articletype"
            label="Chronicles"
            :same-size="true"
            placement="side"
          />
          <ListMostRead class="order-7 lg:order-none" />
          <AdsConceptAd
            v-if="isSite('altinget_dk')"
            class="lg:hidden order-8"
            id="altinget-mob3"
          />
          <AdsConceptAd
            v-else-if="isSite('altinget_se')"
            class="lg:hidden order-3"
            id="altinget-mob3"
          />
          <template v-if="isSite('altinget_no')">
            <AdsConceptAd class="hidden lg:block" id="altinget-rec3" />
            <AdsConceptAd class="lg:hidden order-10" id="altinget-mob4" />
          </template>
          <AdsConceptAd v-else class="hidden lg:block" id="altinget-rec2" />
          <ArticleSponsoredTeaser
            v-if="isSite('altinget_dk', 'altinget_se')"
            class="order-10 lg:order-none"
            :compact="true"
            :top="2"
            :offset="1"
          />
        </template>
      </LayoutColumns>
    </div>
    <ListJobs
      v-if="isSite('altinget_dk')"
      class="no-print order-11 lg:order-none"
      :count="100"
      :is-slider="true"
    />
    <template v-if="isSite('altinget_dk', 'altinget_se')">
      <AdsConceptAd
        class="no-print hidden lg:block"
        id="altinget-lb2"
        :full-width="true"
      />
      <AdsConceptAd
        class="no-print lg:hidden order-15"
        id="altinget-mob4"
        :full-width="true"
      />
    </template>
    <template v-if="isSite('altinget_se', 'altinget_no')">
      <CommonSectionLabel
        class="no-print hidden lg:block"
        title="Birthdays"
        link="Routes.Birthdays"
        link-label="ShowAll"
        :link-to-paper="true"
      />
      <ListBirthdays
        class="no-print hidden lg:block"
        :count="15"
        type="slider"
        placement="full"
      />
    </template>
    <AdsConceptAd
      v-if="isSite('altinget_no')"
      class="no-print hidden lg:block"
      id="altinget-lb2"
      :full-width="true"
    />
    <div class="container no-print">
      <CommonSectionLabel
        class="no-print order-12 lg:order-none"
        title="Debate"
      />
      <LayoutColumns>
        <template #left>
          <AdsConceptAd
            v-if="isSite('altinget_se')"
            class="hidden lg:block"
            id="altinget-rec3"
          />
          <ListColumnists class="order-14 lg:order-none" />
          <template v-if="isSite('altinget_no')">
            <AdsConceptAd class="hidden lg:block" id="altinget-rec4" />
            <AdsConceptAd class="lg:hidden order-8" id="altinget-mob5" />
          </template>
        </template>
        <template #main>
          <ListArticles
            class="order-13 lg:order-none"
            :count="6"
            :offset="0"
            type="column"
            :show-more="true"
            placement="main"
          />
          <ListJobs
            v-if="isSite('altinget_dk')"
            class="order-18 lg:order-none"
            :count="5"
            :is-random-items="true"
            :is-single-page="true"
            :show-header-link="false"
          />
          <template v-if="isSite('altinget_dk')">
            <CommonSectionLabel
              class="order-24 lg:order-none"
              title="Reviews"
            />
            <ListArticles
              class="order-25 lg:order-none"
              :count="4"
              :offset="0"
              :article-type="24"
              type="articletype"
              :show-more="true"
              placement="main"
            />
          </template>
          <template v-else-if="isSite('altinget_se')">
            <CommonSectionLabel
              class="order-24 lg:order-none"
              title="Krönikor"
            />
            <ListArticles
              class="order-25 lg:order-none"
              :count="2"
              :offset="0"
              :article-type="90"
              type="articletype"
              :show-more="true"
              placement="main"
            />
          </template>
          <template v-else-if="isSite('altinget_no')">
            <CommonSectionLabel
              class="order-24 lg:order-none"
              title="Spaltister"
            />
            <ListArticles
              class="order-25 lg:order-none"
              :count="2"
              :offset="0"
              :article-type="72"
              type="articletype"
              :show-more="true"
              placement="main"
            />
          </template>
          <AdsConceptAd
            v-if="isSite('altinget_dk', 'altinget_se')"
            class="lg:hidden order-26"
            id="altinget-mob5"
          />
        </template>
        <template #right>
          <AdsConceptAd
            v-if="isSite('altinget_dk')"
            class="hidden lg:block"
            id="altinget-rec3"
          />
          <ListCalendar
            class="order-17 lg:order-none"
            :count="5"
            placement="side"
          />
          <template v-if="isSite('altinget_dk')">
            <ListArticles
              class="order-21 lg:order-none"
              :count="3"
              :offset="0"
              :article-type="100"
              type="articletype"
              link="/kommentarer"
              link-label="ShowAll"
              label="Comment"
              :same-size="true"
              placement="side"
            />
            <ListArticles
              class="order-22 lg:order-none"
              :count="3"
              :offset="0"
              :article-type="141"
              link="/politiske-taler"
              link-label="ShowAll"
              type="articletype"
              label="PoliticalSpeeches"
              :same-size="true"
              placement="side"
            />
          </template>
          <ListDecisionchains
            v-else-if="isSite('altinget_se')"
            class="hidden lg:block"
            label="DecisionChainShort"
            :count="5"
            placement="side"
          />
          <template v-if="isSite('altinget_no')">
            <AdsConceptAd class="hidden lg:block" id="altinget-rec5" />
            <AdsConceptAd class="lg:hidden order-15" id="altinget-mob6" />
          </template>
        </template>
      </LayoutColumns>
    </div>
    <CommonSectionLabel
      class="no-print hidden lg:block"
      title="Papers"
      link="Routes.Papers"
      link-label="ShowAllPapers"
    />
    <ListPapers class="no-print hidden lg:block" />
    <AdsConceptAd
      class="no-print hidden lg:block"
      id="altinget-lb3"
      :full-width="true"
    />
    <AdsConceptAd
      v-if="isSite('altinget_dk', 'altinget_se')"
      class="no-print lg:hidden order-last"
      id="altinget-mob6"
      :full-width="true"
    />
    <div v-if="isSite('altinget_dk', 'altinget_se')" class="container no-print">
      <CommonSectionLabel class="no-print hidden lg:block" title="NamesNews" />
      <LayoutColumns>
        <template #left>
          <ListBirthdays class="order-16 lg:order-none" placement="side" />
        </template>
        <template #main>
          <ListNames
            class="hidden lg:block"
            :show-more="true"
            placement="main"
          />
        </template>
        <template #right>
          <AdsConceptAd class="hidden lg:block" id="altinget-rec4" />
        </template>
      </LayoutColumns>
    </div>
    <template v-if="isSite('altinget_se')">
      <AdsConceptAd
        class="no-print hidden lg:block"
        id="altinget-lb4"
        :full-width="true"
      />
      <CommonSectionLabel
        class="no-print order-17 lg:order-none"
        title="DecisionChain"
        link="decisionchain/"
      />
      <ListDecisionchains
        class="no-print order-18 lg:order-none"
        :count="15"
        type="slider"
        placement="full"
      />
    </template>
    <div class="container no-print">
      <LayoutColumns>
        <template #left>
          <template v-if="isSite('altinget_no')">
            <AdsConceptAd
              class="hidden lg:block"
              id="altinget-rec7"
              :min-height="250"
            />
            <AdsConceptAd
              class="lg:hidden order-20"
              id="altinget-mob8"
              :min-height="250"
            />
          </template>
          <AdsConceptAd v-else class="hidden lg:block" id="altinget-rec5" />
          <CommonPromo
            class="order-22 lg:order-none"
            icon-type="conversation"
            header1="14DaysTrial"
            header2="Ourmedia"
            cta-label="ReadMoreAndSignup"
            cta="Routes.Subscription"
            disclaimer="AllreadySubscribed"
            :hide-if-logged-in="true"
            placement="side"
          />
        </template>
        <template #main>
          <ListArticles
            class="order-30 lg:order-none"
            :count="8"
            :article-type="1"
            type="articletype"
            :show-more="true"
            placement="main"
          />
        </template>
        <template #right>
          <CommonEditorCard
            v-if="isSite('altinget_dk')"
            class="order-29 lg:order-none"
          />
          <AdsConceptAd class="hidden lg:block" id="altinget-rec6" />
          <ListJobs class="hidden lg:block" :count="5" :random="true" />
        </template>
      </LayoutColumns>
    </div>
  </div>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()
const indexStore = useIndexStore()

if (!indexStore.currentPaper) {
  throw createError({ statusCode: 404 })
}

useSeoMeta({
  title: indexStore.currentPaperSlug
    ? config.public.site.meta.title.postfix.short +
      indexStore.currentPaper?.Name
    : config.public.site.meta.title.postfix.long,
})
</script>
